import React, { useState, useEffect } from 'react';
import { useActiveWeb3React } from '@/hooks';
import { useMsterChefContract } from '@/hooks/useContract';
import { BigNumber } from 'bignumber.js';
import { fixedBalanceAmountRaw } from '@/utils';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useErcContract } from '@/hooks/useContract';
import Loader from '@/components/Loader';

const ListWrap = styled.div`
  width: 1200px;
  color: ${({ theme }) => theme.primary1};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: auto;
  `};

  & .banner-main {
    & h1 {
      font-size: 64px;
      margin-bottom: 24px;
    }
  }
  & main {
    border-radius: 16px;
    overflow: hidden;
    line-height: 22px;
  }
`;
const Item = styled.div`
  background-color: #fff;
  display: flex;
  text-align: center;
  padding: 22px 0;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    & > div {
      flex: none!important;
      width: 33%!important;
      margin-bottom: 10px;
    }
    `};
  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;
const Tag = styled.div`
  width: 59px;
  height: 23px;
  border: 1px solid #349084;
  border-radius: 25px;
`;
const Title = styled.strong`
  display: block;
  width: 100%;
  margin-bottom: 8px;
`;

const loadingContent = (content: any) => {
  return <>{content ? content : <Loader />}</>;
};
interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
}

const Index: React.FC<IndexType> = () => {
  const { account: currentAccount } = useActiveWeb3React();
  const getErcContract = useErcContract();
  const masterChef = useMsterChefContract();
  const [lpsId, setLpsId] = useState<any>([]); // 可以用来更新 pool ID
  const [lps, setLps] = useState<any>([]);

  // 设置核心信息
  useEffect(() => {
    if (!currentAccount || !masterChef) {
      return;
    }
    (async () => {
      // 1.先获取所有LP池
      // 		TODO下面需要换 Multicall
      const basePro = await masterChef.poolLength();
      const list = [];
      for (let index = 0; index < basePro; index++) {
        list.push(index);
      }
      const newList = list;
      newList.shift();
      setLps(newList);
      const flyPerBlock = await masterChef.flyPerBlock();
      const BONUS_MULTIPLIER = await masterChef.BONUS_MULTIPLIER();
      const totalAllocPoint = await masterChef.totalAllocPoint();
      const base = [
        basePro,
        flyPerBlock,
        BONUS_MULTIPLIER,
        totalAllocPoint,
      ].map(item => item.toString());

      // TODO:Sleep
      // 2. 再获取LP信息
      // 		TODO下面需要换 Multicall
      const lpProAResult = [];
      const userPendingResult = [];
      for (let index = 0; index < base[0]; index++) {
        lpProAResult.push(await masterChef.poolInfo(index));
        userPendingResult.push(
          await masterChef.pending(index, currentAccount, 0),
        );
      }

      // 3.再获取所有的Token信息
      // 		TODO下面需要换 Multicallf
      const tokenNames = [];
      const tokenSups = [];
      const lpIdList = [];

      for (let index = 0; index < lpProAResult.length; index++) {
        const tokenAddress = await lpProAResult[index];
        const erc20 = getErcContract(tokenAddress[0]);
        tokenNames.push(await erc20?.symbol());
        tokenSups.push(
          await erc20?.balanceOf('0x5EF3E13e965324A5B8F1538CEC7242641455A7Eb'),
        );
        lpIdList.push({ lpToken: tokenAddress[0], pid: index });
      }
      setLpsId(lpIdList);

      // 组装数据
      const lpsInfo = [];
      for (let index = 0; index < tokenNames.length; index++) {
        const mul = BigNumber(base[1])
          .times(base[2])
          .times(lpProAResult[index].allocPoint.toString())
          .div(base[3])
          .div(10 ** 18)
          .toFixed(1);

        lpsInfo.push({
          lpToken: lpProAResult[index][0],
          symbol: tokenNames[index],
          balanceOf: tokenSups[index].toString(),
          pendingFlyFarm: userPendingResult[index].pendingFlyFarm.toString(),
          pendingFiboFarm: userPendingResult[index].pendingFiboFarm.toString(),
          allocPoint: lpProAResult[index].allocPoint,
          multiplier: mul,
        });
      }
      const newLpList = lpsInfo;
      newLpList.shift();
      // 写入数据
      setLps(newLpList);
    })();
  }, [masterChef, currentAccount]);

  return (
    <>
      <ListWrap>
        <div className="banner-main">
          <h1>农场</h1>
          <h2>质押 LP 代币赚取 FLY Farm & FIBO Farm</h2>
        </div>
        {lps.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px 0',
            }}
          >
            <Loader size="50px" />
          </div>
        )}
        <main>
          {lps.length > 0 &&
            lps.map((item: any, index: number) => {
              return (
                <Item key={index}>
                  <div>
                    <strong>{loadingContent(item?.symbol)}</strong>
                  </div>
                  <div>
                    <Tag>核心</Tag>
                  </div>
                  <div>
                    <Title>已赚取</Title>
                    <div>
                      FLY:
                      {loadingContent(
                        fixedBalanceAmountRaw(item?.pendingFlyFarm, 4),
                      )}
                    </div>
                    &nbsp;
                    <div>
                      FIBO:
                      {loadingContent(
                        fixedBalanceAmountRaw(item?.pendingFiboFarm, 4),
                      )}
                    </div>
                  </div>
                  {/* <div>
                    <Title>APR</Title>
                    <span>🤔️ %</span>
                  </div> */}
                  <div>
                    <Title>流动性</Title>
                    <span>
                      {loadingContent(
                        fixedBalanceAmountRaw(item.balanceOf, 18),
                      )}
                    </span>
                  </div>

                  <div>
                    <Title>倍数</Title>
                    <span>{loadingContent(item.multiplier)}x</span>
                  </div>

                  <div>
                    {loadingContent(
                      item?.lpToken ? (
                        <Link to={`/pool/${item?.lpToken}`}>详情&nbsp;</Link>
                      ) : (
                        ''
                      ),
                    )}
                  </div>
                </Item>
              );
            })}
        </main>
      </ListWrap>
    </>
  );
};
export default Index;
