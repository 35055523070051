import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActiveWeb3React } from '@/hooks';
import { useErcContract } from '@/hooks/useContract';
import { useMsterChefContract } from '@/hooks/useContract';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import Select from 'react-select';
import styled from 'styled-components';
import { ButtonPrimary } from '@/components/Button';
import { StyledBalanceMax } from '@/components/CurrencyInputPanel';
import { Input as NumericalInput } from '@/components/NumericalInput';
import { useAddPopup } from '@/state/application/hooks';
import Loader from '@/components/Loader';
import { ChevronRight } from 'react-feather';
import { toast } from 'react-toastify';
import { masterChef as MASTERCHEF } from '@/constants';
import { NETWORK_CHAIN_ID } from '@/connectors';
import Copy from '@/components/CopyAddress/Copy';
import farmsList from '../1230';
import { TokenNum } from '../historicalBill';

const RightBtn = styled.div`
  font-size: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const NumericalInputBox = styled(NumericalInput)`
  padding: 0;
  margin-right: 8px;
  border: 1px solid ${({ theme }) => theme.primary1};
  background-color: transparent;
  border-radius: 10px;
  padding: 6px 5px;
  width: 100%;
`;
const PoolView = styled.div`
  padding: 0 10px;
  & .banner-main {
    height: 132px;
    width: 100%;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.primary1};
    & h1 {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
`;
const Box = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 26px;
  border-radius: 39px;
  width: 430px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
  color: ${({ theme }) => theme.primary1};

  .select-area {
    width: 100%;
    z-index: 2;
    .css-13cymwt-control {
      background: ${({ theme }) => theme.secondary3};
    }
  }
  & .header {
    font-size: 28px;
    font-weight: bold;
    line-height: 33px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  & .wrap {
    background: ${({ theme }) => theme.secondary3};
    border-radius: 16px;
    padding: 14px;
    margin: 18px 0;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
  }
  & .token-num {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    & .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  & .token-input {
    width: 100%;
    display: flex;
    align-items: center;
  }
  & .token-name {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
`;

const StyledBalanceMaxBtn = styled(StyledBalanceMax)`
  background-color: ${({ theme }) => theme.primary1};
  color: #fff;
`;

const FooterRow = styled.p`
  display: flex;
  align-items: center;
`;
interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
}

const withdrawTypeInitOptions: {
  value: number;
  label: string;
}[] = [
  {
    value: 1,
    label: `锁定类型 (需要账单ID)`,
  },
];

const Index: React.FC<IndexType> = () => {
  const { library, account: currentAccount } = useActiveWeb3React();
  const params: any = useParams();
  const lpAddress = params?.address;
  const addPopup = useAddPopup();
  const getErcContract = useErcContract();
  const masterChef: any = useMsterChefContract();
  const notify = (msg: string) =>
    toast.warning(msg, { position: 'top-center', autoClose: 2200 });

  // const gasValue = await library?.getGasPrice()
  /************************ Data ************************/
  const [userBalance, setUserBalance] = useState('0'); // 用户余额
  const [withdrawBillId, setWithdrawBillId] = useState('0'); // 账单ID
  const [useBillInfo, setUserBillInfo] = useState<any>(null); //
  const [userPending, setUserPending] = useState<any>(null); // 用户待领取奖励
  const [symbol, setSymbol] = useState('');
  const [decimals, setDecimals] = useState<any>('');
  const [allowance, setAllowance] = useState('0');
  const [depositValue, setDepositValue] = useState<string>('0'); // 存款金额
  const [depositType, setDepositType] = useState<number>(0); // 存款类型
  const [withdrawValue, setWithdrawValue] = useState<string>('0'); // 取款金额
  const [withdrawType, setWithdrawType] = useState<number>(
    withdrawTypeInitOptions[0].value,
  ); // 取款类型
  const [submitting, setSubmitting] = useState(false);
  const CONTRACT_ACCOUNT = MASTERCHEF[NETWORK_CHAIN_ID];
  const [useMCBalance, setUserMCBalance] = useState('0'); //

  const [pledgeType, setPledgeType] = useState<any>([]);
  const [tabKey, setTabKey] = useState(true);

  // const initialCount = {
  //   userBalance: '0', // 用户余额
  //   useMCBalance: '0',
  //   withdrawBillId: '0', // 账单ID
  //   useBillInfo: null,
  //   userPending: null, //  用户待领取奖励
  //   symbol: '0',
  //   allowance: '0',
  //   depositValue: '0', // 存款金额
  //   depositType: 0, // 存款类型
  //   withdrawValue: '0', // 取款金额
  //   withdrawType: 0, // 取款类型
  //   submitting: '0',
  //   depositTypes: depositTypeInit,
  //   withdrawTypes: withdrawTypeInit
  // }

  // function reducer(state: any, action: any) {
  //   switch (action.type) {
  //     case 'increment':
  //       return { count: state.count + 1 }
  //     case 'decrement':
  //       return { count: state.count - 1 }
  //     default:
  //       throw new Error()
  //   }
  // }
  // const [state, dispatch] = useReducer(reducer, initialCount)

  // 筛选lp
  const getLp = () =>
    farmsList.find(
      item =>
        lpAddress.toLocaleLowerCase() === item.lpToken.toLocaleLowerCase(),
    );
  const setBigNumber = (num: string) => {
    if (!num) return '';
    return BigNumber(num)
      .div(Math.pow(10, decimals))
      .toFixed(Number(decimals));
  };

  // message
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const orderMessage = (hash: string, fn: any = () => {}) => {
    const toastId = toast.loading('交易确认中...');
    library?.waitForTransaction(hash).then(() => {
      toast.done(toastId);
      setSubmitting(false);
      // hash地址提示
      addPopup({
        txn: {
          hash: hash,
          success: !!hash,
          summary: '链上已经确认',
        },
      });
      fn();
    });
  };

  const setTokenPledge = async (action: string, parms: any) => {
    const toastId = toast.loading('上链中...');
    // const nonce = await library?.getTransactionCount(currentAccount || '')
    try {
      const res = await masterChef[action](...parms);
      if (res?.hash) {
        toast.done(toastId);
        orderMessage(res.hash, () => {
          setSubmitting(false);
          if (tabKey) {
            setDepositValue('0');
            setDepositType(0);
          } else {
            setWithdrawBillId('0');
          }
        });
      }
    } catch (error) {
      const err: any = error;
      if (err?.data?.message.search('locked') != -1) {
        notify('账单未到期');
      }
      toast.done(toastId);
      setSubmitting(false);
    }
  };

  // ****tab1***** //
  // 用户账户余额
  const getUserBalance = async () => {
    if (!currentAccount || !lpAddress || !getErcContract) {
      return;
    }
    const erc20 = getErcContract(lpAddress);
    const balance = (await erc20?.balanceOf(currentAccount)).toString();
    setUserBalance(
      BigNumber(balance)
        .div(Math.pow(10, decimals))
        .toFixed(Number(decimals)),
    );
  };

  // 获取lp信息
  const getLpInfo = async () => {
    if (!getErcContract) {
      return;
    }
    const erc20 = getErcContract(lpAddress);
    if (!erc20) return;
    console.log(erc20, 'erc20');

    const symb = (await erc20?.symbol()).toString();
    const decimals = (await erc20?.decimals()).toString();
    const allowance = (
      await erc20?.allowance(currentAccount, CONTRACT_ACCOUNT)
    ).toString();
    const base = [symb, decimals, allowance];
    setSymbol(base[0]);
    setDecimals(base[1]);
    setAllowance(base[2]);
  };

  const getPledgeType = async () => {
    if (!masterChef) {
      return;
    }
    // 1.先获取所有LP池 + 所有订单类型
    // 		TODO下面需要换 Multicall
    const billList = await masterChef.getAllBillType();
    // 处理订单类型
    const typesArr = [];
    for (let index = 0; index < billList.length; index++) {
      const element = billList[index];
      // TODO: 需要 moment 转换
      const label =
        element.duration === '0'
          ? '活期'
          : `${BigNumber(element.duration.toString())
              .div(60)
              .toFixed(0)} 个月`;
      typesArr.push({
        value: index,
        label: label,
      });
    }
    setPledgeType(typesArr);
  };

  // ****tab2***** //
  // 用户账户在MC中的余额
  const getMcbalance = async () => {
    const balance = (
      await masterChef.userAmount(getLp().pid, currentAccount)
    ).toString();
    setUserMCBalance(balance);
  };
  // 用户账单信息
  const getUserOrderInfi = async () => {
    const info = await masterChef.userInfo(
      getLp().pid,
      currentAccount,
      withdrawBillId,
    );
    setUserBillInfo(info);
  };

  // 获取报酬
  const getRemuneration = async () => {
    const info = await masterChef.pending(
      getLp().pid,
      currentAccount,
      withdrawBillId,
    );

    setUserPending({
      pendingFiboFarm: info.pendingFiboFarm.toString(),
      fiboAddress: await masterChef.fiboFarm(),
      flyAddress: await masterChef.flyFarm(),
      pendingFlyFarm: info.pendingFlyFarm.toString(),
    });
  };

  useEffect(() => {
    if (!currentAccount || !lpAddress || !masterChef || !farmsList) {
      return;
    }
    getLpInfo();
    getUserBalance();
  }, [getErcContract]);

  useEffect(() => {
    getPledgeType();
  }, [masterChef]);

  useEffect(() => {
    if (!tabKey) {
      if (!currentAccount || !lpAddress || !masterChef || !farmsList) {
        return;
      }
      getMcbalance();
      if (withdrawBillId) {
        getUserOrderInfi();
        getRemuneration();
      }
    }
  }, [tabKey, withdrawBillId]);

  // 质押
  const handleDeposit = async () => {
    if (!masterChef) return;
    if (depositValue === '0') {
      notify('请输入金额');
      return;
    }
    if (!(depositType >= 0)) {
      notify('请选择存款类型');
      return;
    }
    if (depositValue > userBalance) {
      notify('余额不足');
      return;
    }
    if (!library) return;
    setSubmitting(true);
    // 确定
    const currentLp: any = farmsList.find(
      item =>
        lpAddress.toLocaleLowerCase() === item.lpToken.toLocaleLowerCase(),
    );
    const action: any = currentLp.pid === 0 ? 'enterStaking' : 'deposit';
    const amount = BigNumber(depositValue)
      .times(Math.pow(10, decimals))
      .toFixed(0);

    const parms: any =
      currentLp.pid === 0
        ? [depositType, amount]
        : [currentLp.pid, depositType, amount];
    setTokenPledge(action, parms);
  };

  // 赎回
  const handleWithdraw = async () => {
    if (!masterChef) return;
    if (!useBillInfo) {
      notify('暂无账单信息');
      return;
    }
    if (withdrawValue === '0') {
      notify('请输入赎回金额');
      return;
    }
    if (
      BigNumber(withdrawValue)
        .times(Math.pow(10, decimals))
        .isGreaterThan(useBillInfo.amount)
    ) {
      setSubmitting(false);
      notify('可取金额不足');
      return;
    }
    if (withdrawValue > userBalance) {
      notify('余额不足');
      return;
    }
    setSubmitting(true);
    // 确定
    const currentLp: any = farmsList.find(
      item =>
        lpAddress.toLocaleLowerCase() === item.lpToken.toLocaleLowerCase(),
    );

    const action = currentLp.pid === 0 ? 'leaveStaking' : 'withdraw';
    const amount = BigNumber(withdrawValue)
      .times(Math.pow(10, decimals))
      .toFixed(0);
    // TODO: useBillInfo
    const parms =
      currentLp.pid === 0
        ? [withdrawBillId, amount]
        : [currentLp.pid, withdrawBillId, amount];
    setTokenPledge(action, parms);
  };
  /**
   *  处理取款
   */
  const handleClaim = async () => {
    if (!masterChef) return;
    if (!useBillInfo) {
      notify('暂无账单信息');
      return;
    }
    if (
      BigNumber(withdrawValue)
        .times(Math.pow(10, decimals))
        .isGreaterThan(useBillInfo.amount)
    ) {
      notify('金额不足');
      return;
    }
    setSubmitting(true);
    // 确定
    const currentLp: any = farmsList.find(
      item =>
        lpAddress.toLocaleLowerCase() === item.lpToken.toLocaleLowerCase(),
    );
    const action = currentLp.pid === 0 ? 'leaveStaking' : 'withdraw';
    const amount = BigNumber(withdrawValue)
      .times(Math.pow(10, decimals))
      .toFixed(0);
    const parms =
      currentLp.pid === 0
        ? [useBillInfo.billId, amount]
        : [currentLp.pid, useBillInfo.billId, amount];

    setTokenPledge(action, parms);
  };

  // 签约
  const enableHandle = async () => {
    const maxUint256Value =
      '115792089237316195423570985008687907853269984665640564039457584007913129639935';
    const erc20: any = getErcContract(lpAddress);
    if (!erc20) return;
    setSubmitting(true);
    const toastId = toast.loading('上链中...');
    try {
      const res = await erc20.approve(CONTRACT_ACCOUNT, maxUint256Value);
      if (res?.hash) {
        toast.done(toastId);
        orderMessage(res?.hash, () => {
          setSubmitting(false);
          getLpInfo();
          getUserBalance();
        });
      }
    } catch (error) {
      toast.done(toastId);
      setSubmitting(false);
    }
  };

  const Btns = (
    <>
      {allowance !== '0' && (
        <RightBtn onClick={() => setTabKey(!tabKey)}>
          去{tabKey ? '赎回' : '质押'} <ChevronRight />
        </RightBtn>
      )}
    </>
  );
  const isNumLoading = (num: any) => <>{decimals && num ? num : <Loader />}</>;

  return (
    <PoolView>
      <div className="banner-main">
        {symbol && (
          <>
            <h1>{symbol} 池</h1>
            <Link
              target="_blank"
              href={`https://scan.fibochain.org/address/${lpAddress}`}
            >
              获取 {symbol}
            </Link>
            <Link
              target="_blank"
              href={`https://scan.fibochain.org/address/${lpAddress}`}
            >
              查看 {symbol} 合约
            </Link>
          </>
        )}
      </div>

      <Box>
        {tabKey && (
          <>
            <strong className="header">质押{Btns}</strong>
            <div className="wrap">
              <div className="token-num">
                <span className="title">质押量</span>
                <span>
                  余额：
                  {isNumLoading(userBalance)}
                </span>
              </div>
              <div className="token-input">
                <NumericalInputBox
                  value={depositValue}
                  onUserInput={setDepositValue}
                />
                <>
                  {currentAccount &&
                    Number(depositValue) !== Number(userBalance) && (
                      <StyledBalanceMaxBtn
                        onClick={() => setDepositValue(userBalance)}
                      >
                        MAX
                      </StyledBalanceMaxBtn>
                    )}
                  <span className="token-name">FIBO-FLY</span>
                </>
              </div>
            </div>
            <div className="wrap">
              <Select
                className="select-area"
                options={pledgeType}
                defaultValue={pledgeType[0]}
                placeholder="* 选择质押类型"
                value={pledgeType.find(
                  (item: any) => item.value === depositType,
                )}
                onChange={t => {
                  setDepositType(t.value);
                }}
              />
            </div>
            {allowance !== '0' && (
              <ButtonPrimary
                className="button"
                onClick={handleDeposit}
                disabled={submitting}
              >
                质押
              </ButtonPrimary>
            )}
          </>
        )}

        {!tabKey && (
          <>
            <div className="header">
              赎回
              {Btns}
            </div>
            <div className="token-num">
              <span className="title">
                总余额
                {isNumLoading(setBigNumber(useMCBalance))}
              </span>
            </div>
            <div>
              <Select
                className="select-area"
                onChange={(t: any) => {
                  setWithdrawType(t.value);
                  t.value === 0 && setWithdrawBillId('0');
                }}
                options={withdrawTypeInitOptions}
                defaultValue={withdrawTypeInitOptions[0]}
                value={withdrawTypeInitOptions.find(
                  (item: any) => item.value === withdrawType,
                )}
                placeholder="* 赎回类型"
              />
            </div>
            {withdrawType !== 0 && (
              <div>
                <p>账单ID</p>
                <NumericalInputBox
                  placeholder="* 请输入锁定账单的ID"
                  value={withdrawBillId}
                  isInteger
                  onUserInput={val => {
                    return setWithdrawBillId(val);
                  }}
                />
                {withdrawBillId && (
                  <div className="bill-info">
                    <strong>账单信息</strong>
                    <ul className="bill-info">
                      <li>
                        Amount: {isNumLoading(useBillInfo?.amount.toString())}
                      </li>
                      <li>
                        Bill ID: {isNumLoading(useBillInfo?.billId.toString())}
                      </li>
                      <li>
                        Bill Type:{' '}
                        {isNumLoading(useBillInfo?.billType.toString())}
                      </li>
                      <li>
                        账单时间:
                        {useBillInfo?.timestamp.toString() === '0'
                          ? '-'
                          : isNumLoading(
                              dayjs(useBillInfo?.timestamp * 1000).format(
                                'YYYY-MM-DD HH:mm',
                              ),
                            )}
                      </li>
                      <li>
                        开始时间:
                        {useBillInfo?.lockStartTime.toString() === '0'
                          ? '-'
                          : isNumLoading(
                              dayjs(useBillInfo?.lockStartTime * 1000).format(
                                'YYYY-MM-DD HH:mm',
                              ),
                            )}
                      </li>
                      <li>
                        结束时间:
                        {useBillInfo?.lockEndTime.toString() === '0'
                          ? '-'
                          : isNumLoading(
                              dayjs(useBillInfo?.lockEndTime * 1000).format(
                                'YYYY-MM-DD HH:mm',
                              ),
                            )}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
            <div>
              <p>
                账单金额：
                {isNumLoading(
                  setBigNumber(useBillInfo ? useBillInfo.amount.toString() : 0),
                )}
              </p>
              <NumericalInputBox
                placeholder="* 请输入质押金额"
                value={withdrawValue}
                onUserInput={setWithdrawValue}
              />
            </div>
            <div>
              <FooterRow>
                <strong>已赚取 FLY Farm :</strong>
                <TokenNum
                  address={userPending?.flyAddress}
                  num={userPending?.pendingFlyFarm.toString()}
                />
                {userPending?.flyAddress && (
                  <Copy toCopy={userPending?.flyAddress} />
                )}
              </FooterRow>
              <FooterRow>
                <strong>已赚取 Fibo Farm :</strong>
                <TokenNum
                  address={userPending?.fiboAddress}
                  num={userPending?.pendingFiboFarm.toString()}
                />
                {userPending?.fiboAddress && (
                  <Copy toCopy={userPending?.fiboAddress} />
                )}
              </FooterRow>
            </div>
            {allowance !== '0' && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ButtonPrimary
                  width="175px"
                  className="button"
                  onClick={handleWithdraw}
                  disabled={submitting}
                >
                  赎回
                </ButtonPrimary>
                <ButtonPrimary
                  width="175px"
                  className="button claim"
                  onClick={handleClaim}
                  disabled={submitting}
                >
                  收益
                </ButtonPrimary>
              </div>
            )}
          </>
        )}
        {allowance === '0' && (
          <ButtonPrimary onClick={enableHandle} disabled={submitting}>
            启用
          </ButtonPrimary>
        )}
      </Box>
    </PoolView>
  );
};
export default Index;
