import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { StyledMenuButton } from '@/components/Settings'
import { useHistory, useLocation } from 'react-router-dom'
import { useWalletModalToggle } from '@/state/application/hooks'
import { useWeb3React } from '@web3-react/core'

const Menu = styled.div`
  display: flex;
  padding: 0 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0;
    width: 100%;
    height: 49px;
    line-height: 49px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.bg1};
    border-top: 1px solid ${({ theme }) => theme.bg2};
    transition: all 0.2s;
  `};
`

const Btn = styled(StyledMenuButton)<{ active: boolean }>`
  height: 100%;
  padding: 0 16px;
  font-size: 16px;
  color: ${({ theme, active }) => (active ? theme.primary1 : '')};
  font-weight: ${({ active }) => (active ? 700 : 500)};
`

interface IndexType {
  isVisible?: boolean
  onClose?: () => any
}
type itemType = {
  name: string
  value: string
}
const list: itemType[] = [
  {
    name: 'swap',
    value: '/swap'
  },
  {
    name: 'farms',
    value: '/farms'
  }
]
const Index: React.FC<IndexType> = props => {
  const { account } = useWeb3React()

  const [activeKey, setActiveKey] = useState('')
  const { location, replace } = useHistory()
  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    setActiveKey(location.pathname)
  }, [location])

  const click = (item: itemType) => {
    if (item.value === '/farms' && !account) {
      toggleWalletModal()
      return
    }
    replace(item.value)
  }

  return (
    <Menu>
      {list.map(item => (
        <Btn
          key={item.value}
          active={activeKey === item.value}
          onClick={() => {
            click(item)
          }}
        >
          {item.name}
        </Btn>
      ))}
    </Menu>
  )
}
export default Index
