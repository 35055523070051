import styled from 'styled-components'
import { ReactComponent as Close } from '@/assets/images/x.svg'
import { Input } from '@/components/AddressInputPanel'
import { ButtonPrimary } from '@/components/Button'

const CloseColor = styled(Close)`
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`
const Main = styled.div`
  padding: 26px 16px;
  color: ${({ theme }) => theme.primary1};
`
const Header = styled.div`
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  background: linear-gradient(126deg, #f1ecf2 0%, #e8f1f6 100%);
  padding: 0 30px 0 21px;
  font-size: 22px;
  font-weight: 800;
  color: #349085;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`
const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  font-weight: bold;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding: 1rem;
`};
`
const ContentData = styled.div`
  background-color: ${({ theme }) => theme.primary5};
  border-radius: 16px;
  padding: 32px 20px;
`
const FooterBox = styled(ContentData)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  margin-top: 27px;
`
const DataItem = styled.div`
  display: flex;
  margin-top: 57px;
  flex-wrap: wrap;
`
const InvitationInput = styled(Input)`
  border-color: transparent;
  height: 59px;
  background: #e9f5f3;
  width: 100%;
  border-radius: 15px;
  padding: 0 10px;
`
const DataItemRow = styled.div`
  margin-top: 15px;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  font-size: 22px;
  & > div:last-child {
    margin-top: 10px;
  }
  & > span {
    font-weight: 800;
    display: inline-block;
  }
  & > div:last-child {
    margin-top: 22px;
    opacity: 0.9;
  }

  &:not(:last-child) {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.primary1};
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
 flex: none;
  width: 100%;
  &:not(:last-child) {
    &::after {
      opacity: 0;
    }
  }
  `}
`

const CopyCodeBox = styled.div`
  & > div {
    background-color: transparent;
    border: 1px solid #22b1cd;
  }
  & input {
    font-size: 24px;
  }
  width: 173px;
`
const FooterButton = styled(ButtonPrimary)`
  width: 293px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 150px;
  `}
`
export {
  CloseColor,
  Main,
  Header,
  HeaderRow,
  ContentData,
  FooterBox,
  DataItem,
  InvitationInput,
  DataItemRow,
  CopyCodeBox,
  FooterButton
}
