import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { Globe } from 'react-feather'
import Select from '@/components/Select'

interface IndexType {
  isVisible?: boolean
  onClose?: () => any
}

const StyledMenuIcon = styled(Globe)`
  height: 20px;
  width: 20px;
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`
const options = [
  {
    label: '简体中文',
    value: 'zh-CN'
  },
  {
    label: 'English',
    value: 'en'
  }
]
const Index: React.FC<IndexType> = () => {
  const { i18n }: any = useTranslation()
  const values = localStorage.getItem('i18nextLng')
  const click = (type: number | string) => {
    i18n.changeLanguage(type)
  }
  return (
    <>
      <Select options={options} defaultValue={values || ''} onClick={click} iconView={<StyledMenuIcon />} />
    </>
  )
}
export default Index
