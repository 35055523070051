import { bech32 } from 'bech32'
import { isAddress } from './index'

// import fibo from 'bech32';

// 地址相互转换 fb->0x
export const addressConvert = (rawAddress: string, chars = 4) => {
  const address = rawAddress.trim()
  let fibo = ''
  if (address.substring(0, 2) === '0x') {
    // 如果是0x开头
    // 0x转fb
    let hexAddr = address
    hexAddr = hexAddr.slice(0, 2) === '0x' ? hexAddr.slice(2) : hexAddr
    const words = bech32.toWords(Buffer.from(hexAddr, 'hex'))
    fibo = bech32.encode('fb', words)
  }
  return {
    fibo,
    rowfibo: `${fibo.substring(0, chars + 2)}...${fibo.substring(42 - chars)}`
  }
}

// 地址转换  0x-fb
export const fiboAddressConvert = (rawAddress: string, chars = 4) => {
  const address = rawAddress.trim()
  let fibo = ''
  if (address.substring(0, 2) === '0x') {
    // 如果是0x开头
    // 0x转fb
    let hexAddr = address
    hexAddr = hexAddr.slice(0, 2) === '0x' ? hexAddr.slice(2) : hexAddr
    const words = bech32.toWords(Buffer.from(hexAddr, 'hex'))
    fibo = bech32.encode('fb', words)
  }
  return {
    address: fibo,
    shortenAddress: `${fibo.substring(0, chars + 3)}...${fibo.substring(41 - chars)}`
  }
}
