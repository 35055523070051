import React, { useState, useEffect } from 'react'
import Modal from '@/components/Modal'
import styled, { ThemeContext } from 'styled-components'
import { ALLOWED_PRICE_IMPACT_HIGH, PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN } from '@/constants'
import { CloseIcon } from '@/theme/components'
import { ButtonError } from '@/components/Button'

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`
const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`
const WalletBotton = styled(ButtonError)`
  margin-top: 20px;
`
// Input
const InputView = styled.input`
  padding: 5px;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.bg1};
  width: 100%;
  height: 40px;
  margin-top: 15px;
`

interface SlipPointModalType {
  isOpen: boolean
  onDismiss: () => void
  type: 'confirm' | 'warning'
  confirm: () => void
}

const SlipPointModal: React.FC<SlipPointModalType> = props => {
  const { isOpen, onDismiss, type, confirm = () => {} } = props
  const [key, setKey] = useState('')
  const click = () => {
    onDismiss?.()
  }

  return (
    <Modal isOpen={isOpen} onDismiss={click} minHeight={false} maxHeight={90}>
      {/* <Wrapper>{getModalContent()}</Wrapper> */}
      <div style={{ flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '10px' }}>
          <HeaderRow>tips</HeaderRow>
          <CloseIcon onClick={click} />
        </div>
        <ContentWrapper>
          {type === 'warning' ? (
            `This swap has a price impact of at least ${ALLOWED_PRICE_IMPACT_HIGH.toFixed(
              0
            )}%. Please confirm that you would like to continue with this swap.`
          ) : (
            <>
              {`This swap has a price impact of at least ${PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN.toFixed(
                0
              )}%. Please type the word "confirm" to continue with this swap.`}
              <InputView value={key} onChange={e => setKey(e.target.value)} />
            </>
          )}
          <WalletBotton error={type === 'warning'} onClick={confirm} disabled={type !== 'warning' && key !== 'confirm'}>
            Confirm
          </WalletBotton>
        </ContentWrapper>
      </div>
    </Modal>
  )
}
export default SlipPointModal
