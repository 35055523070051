import React from 'react'

type IconType = {
  src?: string
  style?: any
}
const Icon: React.FC<IconType> = (props: IconType) => {
  const { src = 'https://chainfb.oss-cn-hangzhou.aliyuncs.com/ETH/1230/FIBO.png' } = props
  return <img style={{ marginRight: '8px', width: '24px' }} {...props} src={src} />
}

export default Icon
