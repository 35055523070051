import { ChainId } from 'flyswap-sdk'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import styled from 'styled-components'
import Logo from '@/assets/images/logo.png'
import { useActiveWeb3React } from '@/hooks'
import { useDarkModeManager } from '@/state/user/hooks'
import { useETHBalances } from '@/state/wallet/hooks'
import { useDerivedSwapInfo } from '@/state/swap/hooks'
import { YellowCard } from '../Card'
import Settings from '../Settings'
import LanguageMenu from './languageMenu'
import Menu from './Menu'
import Invitation from './invitation'
import { useWalletModalToggle } from '@/state/application/hooks'
import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`

const Title = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  /* :hover {
    cursor: pointer;
  } */
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
    cursor: pointer;
  }
  img {
    width: 2.5rem;
    margin-right: 10px;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const RowBetweenTheme = styled.div<{ selected: boolean }>`
  width: 100%;
  align-items: flex-start, 
  border-bottom: 1px solid #eee;
  background: ${({ theme }) => theme.bg1};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: 'ETH',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.FIBO]: 'FIBO'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { currencies } = useDerivedSwapInfo()
  const toggleWalletModal = useWalletModalToggle()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetweenTheme selected={true}>
        <RowBetween padding="8px 1rem">
          <div style={{ display: 'flex' }}>
            <HeaderElement>
              <Title>
                <UniIcon>
                  <img src={isDark ? Logo : Logo} alt="logo" />
                </UniIcon>
                <TitleText>FlySwap</TitleText>
              </Title>
            </HeaderElement>
            <Menu />
          </div>

          <HeaderControls>
            <HeaderElement>
              {<Invitation />}
              <TestnetWrapper>
                {!isMobile && chainId && NETWORK_LABELS[chainId] && (
                  <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>
                )}
              </TestnetWrapper>
              <LanguageMenu />
              <Settings />
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} {chainId && NETWORK_LABELS[chainId] && NETWORK_LABELS[chainId]}
                </BalanceText>
              ) : null}
              <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                <Web3Status />
              </AccountElement>
            </HeaderElement>
          </HeaderControls>
        </RowBetween>
      </RowBetweenTheme>
    </HeaderFrame>
  )
}
