import React, { useState, useEffect } from 'react';
import { useMsterChefContract } from '@/hooks/useContract';
import { useActiveWeb3React } from '@/hooks';
import { isAddress as isAddressBool } from '@/utils';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useErcContract } from '@/hooks/useContract';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';
import Loader from '@/components/Loader';
import { number } from 'prop-types';

dayjs.extend(relativeTime);

const HistoricalBillView = styled.div`
  width: 1200px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: auto;
  `};
  & .content-list {
    border-radius: 25px;
    max-height: 100vh;
    overflow: auto;
  }
  .li {
    display: flex;
    flex-wrap: wrap;
    & div {
      flex: 1;
      margin: auto;
    }
    ${({ theme }) => theme.mediaWidth.upToLarge`
    & div {
      flex: none;
      width: 50%;
    }
  `};
  }
`;
const Item = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  & p {
    font-weight: bold;
  }
  text-align: center;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

export const TokenNum = (props: { address: any; num: any }) => {
  const { address, num } = props;
  const getErcContract = useErcContract();
  const [decimals, setDecimals] = useState<number>(0);

  useEffect(() => {
    (async () => {
      if (!address) return;
      const erc20 = getErcContract(address);
      const res = await erc20?.decimals();
      setDecimals(res);
    })();
  }, [address]);

  return (
    <>
      {decimals && (num ?? '') != '' ? (
        BigNumber(num.toString())
          .div(Math.pow(10, decimals))
          .toFixed(2)
      ) : (
        <Loader />
      )}
    </>
  );
};
interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
}

const HistoricalBill: React.FC<IndexType> = props => {
  const { account } = useActiveWeb3React();
  const isAddress = isAddressBool(account || '');
  const masterChef = useMsterChefContract();
  const [decimals, setDecimals] = useState<any>('');
  /************************ Data ************************/
  const [lps, setLps] = useState<any>([]);
  const [types, setTypes] = useState<any>([]);
  const [list, setList] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(true);

  const getErcContract = useErcContract();

  useEffect(() => {
    if (!isAddress || !masterChef) {
      return;
    }
    (async () => {
      // 1.先获取所有LP池 + 所有订单类型
      // 		TODO下面需要换 Multicall
      const lpLength = await masterChef.poolLength();
      const billList = await masterChef.getAllBillType();

      // 处理订单类型
      const typesArr = [];
      for (let index = 0; index < billList.length; index++) {
        const element = billList[index];
        // TODO: 需要 dayjs 转换
        const label =
          element.duration === '0'
            ? '活期'
            : `${BigNumber(element.duration)
                .div(60)
                .toFixed(0)} 分钟`;
        typesArr.push({
          value: index,
          label: label,
        });
      }
      setTypes(typesArr);
      // TODO:Sleep
      // 2. 再获取LP信息
      // 		TODO下面需要换 Multicall
      const lpProArr = [];
      for (let index = 0; index < lpLength; index++) {
        lpProArr.push(await masterChef.poolInfo(index));
      }
      const lpProAResult = lpProArr;
      // 3.再获取所有的Token信息
      // 		TODO下面需要换 Multicall
      const tokenProArr = [];
      for (let index = 0; index < lpProAResult.length; index++) {
        const tokenAddress = lpProAResult[index].lpToken;
        const erc20 = getErcContract(tokenAddress);
        tokenProArr.push(await erc20?.symbol());
      }
      const tokenNames = tokenProArr;

      // 4.组装数据
      const lpsInfo = [];
      for (let index = 0; index < tokenNames.length; index++) {
        lpsInfo.push({
          value: index,
          label: tokenNames[index],
        });
      }

      // 5.写入数据
      setLps(lpsInfo);
    })();
  }, [isAddress, masterChef]);

  useEffect(() => {
    if (!isAddress || !masterChef || !account) {
      return;
    }
    (async () => {
      const list = await masterChef.getUserHistoryBill(account, 1e10, 100);
      setLoading(false);
      console.log(list, 'list');

      setList(list);
    })();
  }, [isAddress, masterChef, account]);

  return (
    <HistoricalBillView>
      <h1>历史账单</h1>
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}
      >
        {Loading && <Loader size="50px" />}
        {!Loading && !list.length && <p className="null">暂无</p>}
      </div>

      {list.length > 0 && (
        <div className="content-list">
          {list.length > 0 &&
            list.map((item: any) => {
              return (
                <Item key={item.billId} className="li">
                  <div>
                    <p></p>
                    {dayjs.unix(item.timestamp).fromNow()}
                  </div>
                  <div>
                    <p>
                      {item.isDeposit
                        ? '存款'
                        : BigNumber(item.singleAmount).isZero()
                        ? '收割奖励'
                        : '取款'}
                    </p>
                    <span>{item.lpToken.slice(0, 8)}...</span>
                  </div>
                  <div>
                    <p>锁定</p>
                    {/* {item.billType !== '0' && (
                      <> */}
                    <div>
                      开始:
                      {dayjs(item.lockStartTime * 1000).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </div>
                    <div>
                      结束:
                      {dayjs(item.lockEndTime * 1000).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </div>
                    {/* </>
                    )} */}
                  </div>

                  <div>
                    <strong className="red">
                      {item.isDeposit ? '+' : '-'}
                      <TokenNum
                        address={item.lpToken}
                        num={item.singleAmount}
                      />
                    </strong>
                  </div>
                  <div>
                    <strong>
                      <strong>{item.billId.toString()}</strong>
                    </strong>
                  </div>
                  <div>
                    <p>账单ID</p>
                    <div>{item.billId.toString()}</div>
                  </div>
                  <div>
                    {item.lpToken !==
                    '0x0000000000000000000000000000000000000000' ? (
                      <Link
                        to={`/pool/${item.lpToken}?bill_id=${item?.billId}`}
                      >
                        <div>查看池子信息&nbsp;</div>
                      </Link>
                    ) : (
                      <p>--------------</p>
                    )}
                  </div>
                </Item>
              );
            })}
        </div>
      )}
    </HistoricalBillView>
  );
};
export default HistoricalBill;
