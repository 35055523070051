import { ChainId, Token, WETH } from 'flyswap-sdk'
export const FLY = new Token(ChainId.FIBO, '0x4FF286F0B97c2c578f35f99adbe3542fd14f042C', 8, 'FLY', 'Fly Swap Token')
export const USDT = new Token(ChainId.FIBO, '0xAF9455E943797928be2D6CBb010b96D662d2C35E', 18, 'USDT', 'USDT')
export const MK = new Token(ChainId.FIBO, '0xea45249A9EF0287FbbEB43f4f9a6600a1c0d7C4C', 18, 'MK', 'MK Token')

// const obj = {
//   name: 'FiboChain',
//   chainId: 1230,
//   networkId: 1230,
//   explorer_url: 'https://scan.fibochain.org',
//   contracts: {
//     masterChef: '0x5EF3E13e965324A5B8F1538CEC7242641455A7Eb'
//   }
// }

const farms: any[] = [
  {
    pid: 0,
    lpSymbol: 'FIBO-FLY LP',
    lpToken: '0x4FF286F0B97c2c578f35f99adbe3542fd14f042C',
    quoteToken: WETH[ChainId.FIBO],
    token: FLY
  },
  {
    pid: 1,
    lpSymbol: 'FLY-FUSD LP',
    lpToken: '0xb3bE3eDC9D422308B7904c6b49a85273E6946E0c',
    quoteToken: FLY,
    token: USDT
  }
].map(p => ({ ...p }))

export default farms
