import React, { useState } from 'react'
import InvitationModal from '@/components/Modal'
import api from '@/utils/apiList'
import { useActiveWeb3React } from '@/hooks'
import useSWR, { mutate } from 'swr'
import { Flex } from 'rebass'
import { connect, useSelector } from 'react-redux'
import { INVITATION_ADD } from '@/state/Invitation/actions'
import Logo from './LogoRound'
import CopyAddress from '@/components/CopyAddress/CopyAddress'
import { useTranslation } from 'react-i18next'
import { fiboAddressConvert } from '@/utils/fbAddress'
import CopyButton from '@/components/CopyAddress/Copy'
import Loading from '@/components/Loader'
import { toast } from 'react-toastify'
import {
  CloseColor,
  Main,
  Header,
  HeaderRow,
  ContentData,
  FooterBox,
  DataItem,
  InvitationInput,
  DataItemRow,
  CopyCodeBox,
  FooterButton
} from './styles'
import { ButtonPrimary } from '@/components/Button'
import Select from '@/components/Select'

const lPList = [
  {
    label: 'Fibo-Fly',
    value: 'Fibo-Fly'
  },
  {
    label: 'Fibo-USDT',
    value: 'Fibo-USDT'
  }
]

const tokenList = [
  {
    label: 'FIBO',
    icon: 'https://chainfb.oss-cn-hangzhou.aliyuncs.com/ETH/1230/FIBO.png',
    value: 'FIBO'
  }
  // {
  //   label: 'FLY',
  //   icon: 'https://chainfb.oss-cn-hangzhou.aliyuncs.com/ETH/1230/FLY_0x4FF286F0B97c2c578f35f99adbe3542fd14f042C.png',
  //   value: 'FLY'
  // }
]

interface IndexType {
  isShow: boolean
  onDismiss?: () => any
  dispatch: any
}
const InvitationModalView = (props: IndexType) => {
  const InvitationData = useSelector((state: any) => state.Invitation)
  const { chainId, account } = useActiveWeb3React()
  const { t } = useTranslation()
  const [isVitationCode, setInvitationCode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { isShow, onDismiss } = props

  const fetcher = async () => {
    const { data } = await fetch(api.getAddress.url + account).then(res => res.json())
    props?.dispatch({ type: INVITATION_ADD, value: data })
    return data
  }
  useSWR(api.getAddress.url + account, fetcher)

  const Confirm = () => {
    setLoading(true)
    mutate(
      api.addAddress.url,
      fetch(api.addAddress.url, {
        method: api.addAddress.method,
        body: JSON.stringify({
          address: account,
          invitation_code: isVitationCode
        })
      })
        .then(async (res: any) => {
          const data = await res.json()
          setTimeout(() => setLoading(false), 600)
          if (data.code === 200) {
            toast.success('绑定成功', { autoClose: 3000 })
          } else {
            toast.error(data.msg, {
              autoClose: 2000
            })
          }
          props?.dispatch({ type: INVITATION_ADD, value: data.data })
        })
        .catch(() => setLoading(false))
    )
  }
  return (
    <InvitationModal
      isOpen={isShow}
      onDismiss={() => onDismiss?.()}
      maxHeight={100}
      maxWidth={!!InvitationData.address ? 794 : 325}
      overflow="none"
      {...props}
    >
      <div style={{ width: '100%' }}>
        <Header>
          <HeaderRow>{t(InvitationData.address ? 'Proceeds' : 'Please fill in the invitation code')}</HeaderRow>
          <CloseColor onClick={() => onDismiss?.()} />
        </Header>
        <Main>
          {!InvitationData.address ? (
            <>
              <InvitationInput
                placeholder={t('Input')}
                value={isVitationCode}
                onChange={event => {
                  if (event.currentTarget.validity.valid) {
                    setInvitationCode(event.target.value.replace(/,/g, '.'))
                  }
                }}
                disabled={loading}
              />

              <Flex justifyContent="center" alignItems="center" style={{ height: '120px' }}>
                {loading && <Loading size="50px" />}
              </Flex>
              <ButtonPrimary width="100%" onClick={Confirm} disabled={!isVitationCode || loading}>
                {t('Confirm')}
              </ButtonPrimary>
            </>
          ) : (
            <>
              <ContentData>
                <Flex justifyContent="space-between">
                  <CopyCodeBox>
                    <CopyAddress account={InvitationData.invitation_code} />
                  </CopyCodeBox>
                  <Logo style={{ width: '53px', height: '53px' }} />
                </Flex>
                <DataItem>
                  <DataItemRow>
                    <span>{InvitationData.invitation_count}</span>
                    <div>{t('Number of invitees')}</div>
                  </DataItemRow>
                  {InvitationData?.parent_address && (
                    <DataItemRow>
                      <Flex alignItems="center" justifyContent="center">
                        {InvitationData.parent_address &&
                          fiboAddressConvert(InvitationData.parent_address).shortenAddress}
                        <CopyButton toCopy={fiboAddressConvert(InvitationData.parent_address).address} />
                      </Flex>
                      <div>{t('invitation address')}</div>
                    </DataItemRow>
                  )}
                  <DataItemRow>
                    <Flex justifyContent="center" alignItems="center">
                      {InvitationData.tvl} &nbsp;
                      {/* <Select options={lPList} /> */}
                      FIBO-FLY
                    </Flex>
                    <div>{t('your pledge')}</div>
                  </DataItemRow>
                </DataItem>
              </ContentData>
              <FooterBox>
                <Flex alignItems="center">
                  <span style={{ fontWeight: 800, fontSize: '24px' }}>{InvitationData.available_amount}</span>
                  <Select options={tokenList} />
                </Flex>
                <FooterButton disabled>{t('extract')}</FooterButton>
              </FooterBox>
            </>
          )}
        </Main>
      </div>
    </InvitationModal>
  )
}
export default connect(state => state)(InvitationModalView)
