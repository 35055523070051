export const INVITATION_ADD = 'Invitation_Add'

// AppUserInfo
// eslint-disable-next-line camelcase
export const invitationAdd = (userInfo: any) => {
  return {
    type: INVITATION_ADD,
    value: userInfo
  }
}
