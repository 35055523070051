const DEFAULT_URL = process.env.REACT_APP_ENV;

let URL = '';
if (DEFAULT_URL === 'development') {
  URL = 'http://154.85.62.206:12345';
} else if (DEFAULT_URL === 'test') {
  URL = 'http://154.85.62.206:12345';
} else if (DEFAULT_URL === 'production') {
  URL = 'https://service3.fly-swap.com';
}

type dataType = {
  [key: string]: {
    url: string;
    method: 'POST' | 'GET';
  };
};
const data: dataType = {
  // 通过地址获取信息
  getAddress: {
    url: `${URL}/api/v1/address/`,
    method: 'GET',
  },

  // 检测是否填过用户调查
  addAddress: {
    url: `${URL}/api/v1/address`,
    method: 'POST',
  },
};

export default data;
