import React from 'react'
import styled from 'styled-components'
import CopyButton from '@/components/CopyAddress/Copy'

const Flex = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  position: relative;
  background-color: ${({ theme }) => theme.primary5};
  padding: 0;
`

const CopyButtonView = styled.div`
  margin: 18px 10px 18px 0;
`

const Address = styled.div`
  flex: 1;
  position: relative;
  padding-left: 16px;

  & > input {
    background: transparent;
    border: 0;
    display: block;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    width: 100%;
    color: ${({ theme }) => theme.primary1};
    &:focus {
      outline: 0;
    }
  }

  &:after {
    background: linear-gradient(to right);
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`
interface CopyAddressType {
  account: any
}

const CopyAddress = (props: CopyAddressType) => {
  const { account } = props
  return (
    <Flex {...props}>
      <Address title={account}>
        <input type="text" readOnly value={account} />
      </Address>
      <CopyButtonView>
        <CopyButton toCopy={account} size="20" />
      </CopyButtonView>
    </Flex>
  )
}

export default CopyAddress
