import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonGray } from '@/components/Button';
import InvitationModal from './invitationModal';
import { useWalletModalToggle } from '@/state/application/hooks';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';

const Btn = styled(ButtonGray)`
  height: 32px;
  background: #eff5f5;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #349085;
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const Invitation = () => {
  const { account } = useWeb3React();
  const { t } = useTranslation();

  const toggleWalletModal = useWalletModalToggle();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const click = () => {
    if (account) {
      setShowConfirmation(true);
    } else {
      toggleWalletModal();
    }
  };
  return (
    <>
      <Btn onClick={click}>{t('invite')}</Btn>
      {account && (
        <InvitationModal
          isShow={showConfirmation}
          onDismiss={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};
export default Invitation;
